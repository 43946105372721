import BooleanField from '@/dc-it/types/BooleanField'
import Field from '../types/Field'
import Relation from '../types/Relation'

import Model from './Model'
import State from './State'

export default class City extends Model {
    endpoint = 'cities';

    fields = [
      new Field('id', 'Código').disabled(),
      new Relation('state_id', 'Estado', State, 'name'),
      new Field('name', 'Nombre'),
      new BooleanField('status', 'Estado').hide(),
    ]

    clone = () => City;
}
