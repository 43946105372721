import BooleanField from '@/dc-it/types/BooleanField'
import Field from '../types/Field'

import Model from './Model'

export default class State extends Model {
    endpoint = 'states';

    fields = [
      new Field('id', 'Código').disabled(),
      new Field('name', 'Nombre'),
      new BooleanField('status', 'Status'),
    ]

    clone = () => State;
}
